
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        









































































































.campaign-setup-suppression {
  @include stage-layout;

  @apply tw-mt-0;

  section {
    padding-bottom: $--clb-layout-4;
  }

  p {
    max-width: 700px;
  }

  &__error {
    color: $--clb-color-info;
  }

  .el-button-group {
    width: 100%;
    transition: none;

    .campaign-setup-suppression__button {
      display: inline-block;
      margin: $--clb-layout-2 0 0 0;
      height: 40px;
      width: 50% !important;
      font-size: $--clb-font-size-xs;
      transition: $--all-transition, width 0ms;

      i {
        display: none;
      }

      &:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.75);
        margin-right: 0 !important;
      }

      &:active,
      &:hover,
      &:focus {
        transform: translateY(0) !important;
      }
    }
  }

  @media (min-width: $--sm) {
    .el-button-group {
      width: auto !important;

      .campaign-setup-suppression__button {
        width: auto !important;

        i {
          display: inline;
        }
      }
    }
  }

  @media (min-width: $--md) {
    .el-button-group {
      .campaign-setup-suppression__button {
        font-size: $--clb-font-size-sm;
      }
    }
  }
}
