
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















.campaign-suppression-skeleton {
  width: 100%;
  max-width: 700px;

  svg {
    margin: $--clb-space-1 0;
  }
}
